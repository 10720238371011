import * as React from "react"
import { Link } from "react-router-dom"
import { Alert, AlertVariant } from "@allied/react-web/Alert"
import { Button } from "@allied/react-web/Button"
import { Card } from "@allied/react-web/Card"
import { Field, FieldNotif } from "@allied/react-web/Field"
import { Hero } from "@allied/react-web/Hero"
import { Image } from "@allied/react-web/Image"
import { InputText, InputEmail, InputPhone } from "@allied/react-web/Input"
import { Loader } from "@allied/react-web/Loader"
import { MapViewer } from "@allied/react-web/MapViewer"
import { Meta } from "@allied/react-web/Meta"
import { Textarea } from "@allied/react-web/Textarea"
import {
  useAutoPosition,
  useHashFragment,
  useValidator, Validator,
} from "@allied/react-web/hooks"
import { Contact as ContactService } from "../services/api/Contact"

import winspecHero from "../assets/image/island-wide.webp"
import contactIcon from "../assets/image/driver.webp"

const contactService = new ContactService({
  host: process.env.REACT_APP_API_HOST
})

const locations = [
  {
    name: "Head Office",
    address: "No. 6 Tuas Avenue 6, Singapore 639311",
    mapQuery: "Winspec Logistics Services Pte. Ltd.",
    contacts: [
      {
        label: "(Tel) +65 6697 8952",
        value: "tel:+6566978952"
      }
    ]
  }
]

export default function Contact(): React.JSX.Element {
  useAutoPosition()
  useHashFragment()

  const [selectedLocation, setSelectedLocation] = React.useState({
    name: "",
    address: "",
    mapQuery: "",
    contacts: []
  })
  const [contactNotif, setContactNotif] = React.useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  })
  const [contactAlert, setContactAlert] = React.useState({
    header: <></>,
    content: <></>,
    variant: AlertVariant.DANGER,
    open: false,
    closeable: true,
  })
  const [contactForm, setContactForm] = React.useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    loading: false
  })
  const [contactValidator] = useValidator({
    name: Validator.string().required().min(3).max(256),
    email: Validator.string().required().email().min(3).max(256),
    phone: Validator.string().required().phone("SG").min(5).max(17).trim(),
    message: Validator.string().required().min(3),
  })

  React.useEffect(() => {
    if (locations.length === 0) {
      return
    }

    setTimeout(() => {
      setSelectedLocation(locations[0])
    }, 1000)
  }, [])

  const handleContactSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    setContactNotif({
      name: "",
      email: "",
      phone: "",
      message: "",
    })

    const validateRes = contactValidator.validate({
      name: contactForm.name,
      email: contactForm.email,
      phone: contactForm.phone,
      message: contactForm.message || "Blank",
    })
    if (validateRes.error) {
      const err = validateRes.error

      if (err instanceof Validator.ValidationError) {
        setContactNotif((prevState) => {
          return {
            ...prevState,
            [err.path]: err.errors.join("\n")
          }
        })
      } else {
        console.warn("unexpected error: ", err)
      }

      return
    }

    setContactAlert((prevState) => {
      return {
        ...prevState,
        open: true,
        closeable: false,
        variant: AlertVariant.INFO,
        header: (
          <>Sending contact enquiry</>
        ),
        content: (
          <>
            <p className="italic">Please wait a moment...</p>
          </>
        )
      }
    })
    setContactForm((prevState) => {
      return {
        ...prevState,
        loading: true
      }
    })
    const sendRes = await contactService.SendEnquiry({
      ...validateRes.data
    })
    setContactForm((prevState) => {
      return {
        ...prevState,
        loading: false
      }
    })
    if (sendRes.error) {
      setContactAlert((prevState) => {
        return {
          ...prevState,
          open: true,
          closeable: true,
          variant: AlertVariant.DANGER,
          header: (
            <>Failed send enquiry</>
          ),
          content: (
            <>
              <p className="my-2 italic">
                Please try again later or contact us directly via phone or email
              </p>
              <p><span className="font-bold">Code:</span> {sendRes.error.code}</p>
              <p><span className="font-bold">Message:</span> {sendRes.error.message}</p>
            </>
          )
        }
      })
      return
    }

    setContactAlert((prevState) => {
      return {
        ...prevState,
        open: true,
        closeable: true,
        variant: AlertVariant.SUCCESS,
        header: (
          <>Success send enquiry</>
        ),
        content: (
          <>Thank you for your response. We'll be in touch with you soon.</>
        )
      }
    })
    setContactForm((prevState) => {
      return {
        ...prevState,
        name: "",
        email: "",
        phone: "",
        message: "",
      }
    })
  }

  return (
    <>
      <Meta>
        {{
          title: "Contact",
          description: "Winspec Group has over 4 locations islandwide. Contact us for enquiries on warehouse, distribution, freight & marine lubricants.",
          keywords: "winspec singapore, winspec group, winspec logistics services pte ltd, winspec shipping pte ltd, sea freight services, air freight services, warehousing, transportation, distribution, container trucking"
        }}
      </Meta>

      <Hero.Winspec
        title="Over 4 locations islandwide"
        primaryAction="#location"
        primaryLabel="Contact Us"
        backgroundImage={winspecHero} />

      <div id="location" className="scroll-my-16 my-6 md:!my-12 px-3 md:!px-24 3xl:!px-64">
        <div className="flex flex-col flex-wrap justify-center">
          <h1 className="text-3xl md:!text-4xl font-semibold py-2">
            Our Locations
          </h1>
          <hr />

          <Loader lazy>
            <div className="grid grid-cols-12 gap-4 py-4">
              <div className="col-span-12 md:!col-span-6 order-last md:!order-first">
                <div className="flex flex-col gap-3">
                  {
                    locations.map((location, i: number) => {
                      return (
                        <Card key={`location-item-${i}`} size="md"
                          onClick={() => {
                            setSelectedLocation(location)
                          }}
                          clickable>
                          <h1 className="text-lg md:!text-2xl font-semibold mb-4">
                            {location.name}
                          </h1>
                          <p>
                            {location.address}
                          </p>
                          {
                            location.contacts.map((contact, j: number) => {
                              return (
                                <Link key={`contact-item-${j}`} to={contact.value}>
                                  {contact.label}
                                </Link>
                              )
                            })
                          }
                        </Card>
                      )
                    })
                  }
                </div>
              </div>
              <div className="col-span-12 md:!col-span-6">
                <div className="h-96">
                  <MapViewer provider="gmap"
                    appendClassNames="rounded-md"
                    zoom={17}
                    query={selectedLocation.mapQuery} />
                </div>
              </div>
            </div>
          </Loader>
        </div>
      </div>

      <div id="contact" className="scroll-my-16 my-6 md:!my-12 px-3 md:!px-24 3xl:!px-64">
        <div className="flex flex-col flex-wrap justify-center">
          <h1 className="text-3xl md:!text-4xl font-semibold py-2">
            Drop Us A Message
          </h1>
          <hr />

          <div className="grid grid-cols-12 gap-4 py-4">
            <div className="col-span-12 md:!col-span-6 hidden md:flex">
              <Image src={contactIcon} alt="contact us"
                className="max-w-full h-auto object-cover rounded-md" lazy />
            </div>
            <div className="col-span-12 md:!col-span-6">
              <p className="mb-4">
                Please note that we only answer enquiries on warehouse, distribution, freight & marine lubricants.
                For depot & trucking related matters, please make an enquiry at <Link to="https://allied.com.sg/" className="text-blue-500" target="_blank">Allied</Link> website.
              </p>
              <Alert
                variant={contactAlert.variant}
                opened={contactAlert.open}
                closeable={contactAlert.closeable}
                onClose={() => {
                  setContactAlert((prevState) => {
                    return {
                      ...prevState,
                      open: false
                    }
                  })
                }}>
                {{
                  header: contactAlert.header,
                  content: contactAlert.content
                }}
              </Alert>
              <form method="POST" onSubmit={handleContactSubmit}>
                <div className="flex flex-col gap-3">
                  <Field>
                    <label htmlFor="name">Name</label>
                    <InputText id="name" name="name" placeholder="Name *"
                      value={contactForm.name}
                      onChange={(e) => {
                        setContactForm((prevState) => {
                          return {
                            ...prevState,
                            name: e.target.value,
                          }
                        })
                      }}
                      required disabled={contactForm.loading} />
                    <FieldNotif>{contactNotif.name}</FieldNotif>
                  </Field>
                  <Field>
                    <label htmlFor="phone">Phone Number</label>
                    <InputPhone id="phone" name="phone" placeholder="Contact Number *"
                      value={contactForm.phone}
                      onUpdate={(m) => {
                        setContactForm((prevState) => {
                          return {
                            ...prevState,
                            phone: m.phone_number,
                          }
                        })
                      }}
                      required disabled={contactForm.loading} />
                    <FieldNotif>{contactNotif.phone}</FieldNotif>
                  </Field>
                  <Field>
                    <label htmlFor="email">Email Address</label>
                    <InputEmail id="email" name="email" placeholder="E-mail *"
                      value={contactForm.email}
                      onChange={(e) => {
                        setContactForm((prevState) => {
                          return {
                            ...prevState,
                            email: e.target.value,
                          }
                        })
                      }}
                      required disabled={contactForm.loading} />
                    <FieldNotif>{contactNotif.email}</FieldNotif>
                  </Field>
                  <Field>
                    <label htmlFor="message">Message</label>
                    <Textarea id="message" name="message" placeholder="Your Message"
                      value={contactForm.message}
                      onChange={(e) => {
                        setContactForm((prevState) => {
                          return {
                            ...prevState,
                            message: e.target.value,
                          }
                        })
                      }}
                      disabled={contactForm.loading} />
                    <FieldNotif>{contactNotif.message}</FieldNotif>
                  </Field>
                  <div className="flex justify-center items-center">
                    <Button type="submit" variant="primary"
                      disabled={contactForm.loading}
                      appendClassNames="w-1/2">
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
