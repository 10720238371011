import * as React from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"
import ReactGA from "react-ga4"
import { MetaProvider } from "@allied/react-web/Meta"
import { ThemeProvider } from "@allied/react-web/Theme"
import { routes } from "./routes"
import "./index.css"

ReactGA.send({ hitType: "pageview", page: window.location.pathname })

const meta = {
  titleTemplate: "Winspec %s",
  titleFallback: "Winspec Singapore",
  descriptionFallback: "Winspec Group is one of the Singapore&rsquo;s leading providers of freight forwarding and logistics who can provide one-stop logistics provider solution to our customers.",
  keywordsFallback: "winspec singapore, winspec group, winspec logistics services pte ltd, winspec shipping pte ltd, sea freight services, air freight services, warehousing, transportation, distribution, container trucking",
  typeFallback: "website",
  urlFallback: window.location.href,
  imageFallback: `${process.env.REACT_APP_PUBLIC_URL}/image/container-preview.webp`,
  markupSchemaFallback: {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Winspec Singapore",
    "image": "https://winspecgroup.com/image/container-preview.webp",
    "@id": "https://winspecgroup.com/",
    "url": "https://winspecgroup.com/",
    "telephone": "+6565586259",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "6 Tuas Ave 6",
      "addressLocality": "Singapore",
      "postalCode": "639311",
      "addressCountry": "SG"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 1.3258101,
      "longitude": 103.6501101
    },
    "sameAs": [
      "https://www.facebook.com/WinspecGroupp/",
      "https://www.linkedin.com/company/winspecgroup/"
    ]
  },
  twitterSchemaFallback: {
    "card": "summary_large_image",
  }
}

export default function App(): React.JSX.Element {
  return (
    <>
      <ThemeProvider theme={"winspec"}>
        <MetaProvider meta={meta}>
          <Router>
            <Routes>
              {
                routes.map((route, i) => {
                  return <Route {...route} key={`route-item-${i}`} />
                })
              }
            </Routes>
          </Router>
        </MetaProvider>
      </ThemeProvider>
    </>
  )
}
