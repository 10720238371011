import * as React from "react"
import { Accordion, AccordionTheme, AccordionSize } from "@allied/react-web/Accordion"
import { ButtonLink, ButtonSize } from "@allied/react-web/Button"
import { Select, SelectSize } from "@allied/react-web/Select"
import { Hero } from "@allied/react-web/Hero"
import { Loader } from "@allied/react-web/Loader"
import { Meta } from "@allied/react-web/Meta"
import { Spinner } from "@allied/react-web/Spinner"
import {
  useAutoPosition,
  useHashFragment
} from "@allied/react-web/hooks"
import { Job as JobService } from "../services/gsheet/Job"
import winspecHero from "./../assets/image/loading.webp"
import employmentIcon from "./../assets/icon/employment.svg"
import departmentIcon from "./../assets/icon/department.svg"

const filterEmployments = [
  {
    value: "",
    label: "Type"
  },
  {
    value: "Full Time",
    label: "Full Time"
  },
  {
    value: "Part Time",
    label: "Part Time"
  },
  {
    value: "Contract",
    label: "Contract"
  }
]
const filterDepartments = [
  {
    value: "",
    label: "Department"
  },
  {
    value: "Operations",
    label: "Operations"
  },
  {
    value: "HR",
    label: "HR"
  },
  {
    value: "Accounts",
    label: "Accounts"
  },
  {
    value: "IT",
    label: "IT"
  },
]

type JobItem = {
  title: string
  description: string
  responsibility: string
  requirement: string
  company_name: string
  department_name: string
  employment_type: string
}

const jobService = new JobService()

export default function Career(): React.JSX.Element {
  const [jobs, setJobs] = React.useState<JobItem[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [filter, setFilter] = React.useState({
    employment: "",
    department: ""
  })
  const currentTs = new Date()

  useAutoPosition()
  useHashFragment()

  React.useEffect(() => {
    async function searchJob() {
      setLoading(true)
      const searchJob = await jobService.SearchJob({
        department: filter.department,
        employment: filter.employment,
      })
      setLoading(false)
      if (searchJob.error) {
        return
      }

      setJobs(searchJob.data.items)
    }

    searchJob()
  }, [filter])

  return (
    <>
      <Meta>
        {{
          title: "Careers",
          description: "Winspec Group Recruitment. Join our team and make a difference!"
        }}
      </Meta>

      <Hero.Winspec
        title="Find your next job at Winspec"
        description={`Updated as of ${currentTs.toLocaleString("default", { month: "long" })} 2023`}
        primaryAction="#job"
        primaryLabel="Get Started"
        backgroundImage={winspecHero} />

      <div id="job" className="scroll-my-16 py-10 md:!py-16 px-3 md:!px-24 3xl:!px-64">
        <div className="flex flex-col flex-wrap justify-center">
          <h1 className="text-2xl md:!text-4xl md:!text-4xl font-medium md:!font-semibold py-2">
            Job Postings
          </h1>
          <hr />

          <div className="flex justify-between items-center py-4">
            <p className="text-xl md:!text-2xl font-medium md:!font-semibold w-1/3">
              Filter
            </p>

            <div className="flex justify-center items-center w-2/3 lg:w-1/3 gap-4">
              <Select size={SelectSize.MEDIUM}
                id="filter-employment-type"
                name="filter-employment-type"
                onChange={(e) => {
                  setFilter((prevState) => {
                    return {
                      ...prevState,
                      employment: e.target.value
                    }
                  })
                }}>
                {
                  filterEmployments.map((filter, i: number) => {
                    return (
                      <Select.Item key={`employment-type-item-${i}`} value={filter.value}>
                        {filter.label}
                      </Select.Item>
                    )
                  })
                }
              </Select>
              <Select size={SelectSize.MEDIUM}
                id="filter-department"
                name="filter-department"
                onChange={(e) => {
                  setFilter((prevState) => {
                    return {
                      ...prevState,
                      department: e.target.value
                    }
                  })
                }}>
                {
                  filterDepartments.map((filter, i: number) => {
                    return (
                      <Select.Item key={`department-item-${i}`} value={filter.value}>
                        {filter.label}
                      </Select.Item>
                    )
                  })
                }
              </Select>
            </div>
          </div>

          <Loader lazy>
            <div className="flex flex-col lg:flex-row md:flex-nowrap justify-center items-start gap-4">
              <div className="w-full">
                <div className="py-2">
                  {
                    loading &&
                    <Spinner size="sm" />
                  }

                  {
                    !loading && jobs.length === 0 &&
                    <span className="text-xl md:!text-2xl font-medium md:!font-semibold">
                      No jobs available.
                    </span>
                  }

                  {
                    !loading && jobs.length > 0 &&
                    <Accordion
                      theme={AccordionTheme.ALLIEDCONTAINER}
                      size={AccordionSize.MEDIUM}>
                      {{
                        items: jobs.map((job) => {
                          return {
                            header: (
                              <h2 className="text-xl font-medium md:!font-semibold">
                                {job.title}
                              </h2>
                            ),
                            content: (
                              <>
                                <div className="flex justify-start items-center gap-2 w-full my-2">
                                  <img className="w-4 h-4" src={employmentIcon} alt="employement" />
                                  <span>
                                    {job.employment_type}
                                  </span>
                                  <img className="w-4 h-4" src={departmentIcon} alt="department" />
                                  <span>
                                    {job.department_name}
                                  </span>
                                </div>
                                <hr className="my-2" />
                                <div className="flex flex-col justify-center items-start gap-4">
                                  <p className="text-xl font-medium md:!font-semibold">
                                    Description
                                  </p>
                                  <div dangerouslySetInnerHTML={{ __html: job.description }}></div>
                                  <p className="text-xl font-medium md:!font-semibold">
                                    Key Responsibilities
                                  </p>
                                  <div dangerouslySetInnerHTML={{ __html: job.responsibility }}></div>
                                  <p className="text-xl font-medium md:!font-semibold">
                                    Requirements
                                  </p>
                                  <div dangerouslySetInnerHTML={{ __html: job.requirement }}></div>
                                  <ButtonLink size={ButtonSize.MEDIUM}
                                    to={`mailto:hr@allied.com.sg?subject=Application for ${job.title}`}>
                                    Apply Now
                                  </ButtonLink>
                                </div>
                              </>
                            )
                          }
                        })
                      }}
                    </Accordion>
                  }
                </div>
              </div>
            </div>
          </Loader>
        </div>
      </div>
    </>
  )
}
