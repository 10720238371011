import * as React from "react"
import { hydrate, render } from "react-dom"
import { reportWebVitals } from "./scripts/WebVitals"
import { initGoogleAnalytics } from "./scripts/GoogleAnalytics"
import App from "./App"

import "./index.css"

const rootElement = document.getElementById("root")
const indexComponent = <>
  <React.StrictMode>
    <App />
  </React.StrictMode>
</>

if (rootElement.hasChildNodes()) {
  hydrate(indexComponent, rootElement)
} else {
  render(indexComponent, rootElement)
}

initGoogleAnalytics({
  gaId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID
})
reportWebVitals()