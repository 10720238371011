import * as Papa from "papaparse"
import {
  Status,
  ACTION_SUCCESS,
  UNEXPECTED_ERROR
} from "../Status"
import {
  SearchJobParam, SearchJobResult,
} from "../Job"

type JobItem = {
  title: string
  description: string
  responsibility: string
  requirement: string
  company_name: string
  department_name: string
  employment_type: string
}

export class Job {

  private companyName = "Winspec Logistics Services Pte Ltd"
  private jobSource = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQddtjFLj6swt4bf-Pfi8cpitZhybwwZHNFg8FbyQyKi3u4c2aLtP6JDnG3xa9O9GKbGl5T-bCkoMz6/pub?gid=0&single=true&output=csv"

  public async SearchJob(p: SearchJobParam): Promise<SearchJobResult> {
    try {
      const jobRes = await fetch(this.jobSource)
      const text = await jobRes.text()
      const parseRes = Papa.parse(text)

      let jobs = parseRes.data.filter((item?: any[]) => {
        return Array.isArray(item) && item.length >= 8
      }).map((item: any[]) => {
        return {
          title: item[4],
          description: item[5],
          responsibility: item[6],
          requirement: item[7],
          company_name: item[1],
          department_name: item[2],
          employment_type: item[3],
        } as JobItem
      }).map((item) => {
        return {
          ...item,
          responsibility: item.responsibility
            .split("\n")
            .map((char) => `<p>${char}</p>`)
            .join(" "),
          requirement: item.requirement
            .split("\n")
            .map((char) => `<p>${char}</p>`)
            .join(" ")
        }
      })

      jobs = jobs.filter((item: JobItem) => {
        return item.company_name === this.companyName
      })

      if (p.department) {
        jobs = jobs.filter((item: JobItem) => {
          return item.department_name === p.department
        })
      }

      if (p.employment) {
        jobs = jobs.filter((item: JobItem) => {
          return item.employment_type === p.employment
        })
      }

      return {
        success: new Status("success search job", ACTION_SUCCESS),
        data: {
          items: jobs,
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

}
