import * as React from "react"
import { Link } from "react-router-dom"
import { Button } from "@allied/react-web/Button"
import { Carousel } from "@allied/react-web/Carousel"
import { Hero } from "@allied/react-web/Hero"
import { Image } from "@allied/react-web/Image"
import { Meta } from "@allied/react-web/Meta"
import { Modal } from "@allied/react-web/Modal"
import {
  useAutoPosition,
  useHashFragment,
} from "@allied/react-web/hooks"
import winspecLogo from "../assets/logo/winspec-logo.png"
import winspecHero from "../assets/image/building.webp"
import winspecAbout from "../assets/image/container.webp"
import service1 from "../assets/image/truck.webp"
import service2 from "../assets/image/warehouse.webp"
import service3 from "../assets/image/oil.webp"

type Menu = {
  title: string
  teams: Team[]
}

type Team = {
  name: string
  designation: string
  contacts: Contact[]
}

type Contact = {
  label: string
  value: string
  note?: string
}

const menu = new Map<string, Menu>()
menu.set("logistic", {
  title: "Logistics Services Team",
  teams: [
    {
      name: "Mr Wilson",
      designation: "Warehouse Manager",
      contacts: [
        {
          label: "wilson@winspeclog.com.sg",
          value: "mailto:wilson@winspeclog.com.sg"
        },
        {
          label: "+65 6558 6200",
          value: "tel:+6565586200"
        }
      ]
    },
    {
      name: "Mr Frankie",
      designation: "Distribution",
      contacts: [
        {
          label: "frankie@winspeclog.com.sg",
          value: "mailto:frankie@winspeclog.com.sg"
        },
        {
          label: "+65 6232 7589",
          value: "tel:+6562327589"
        }
      ]
    },
    {
      name: "Ms Karen",
      designation: "Admin Manager",
      contacts: [
        {
          label: "karen@winspeclog.com.sg",
          value: "mailto:karen@winspeclog.com.sg"
        },
        {
          label: "+65 6558 6259",
          value: "tel:+6565586259"
        },
        {
          label: "+65 6897 8952",
          value: "tel:+6568978952"
        }
      ]
    }
  ]
})
menu.set("shipping", {
  title: "Shipping Team",
  teams: [
    {
      name: "Mr Edwin Lim",
      designation: "Sales Executive",
      contacts: [
        {
          label: "edwinlim@winspeclog.com.sg",
          value: "mailto:edwinlim@winspeclog.com.sg"
        },
        {
          label: "+65 8218 8471",
          value: "tel:+6582188471",
          note: "(SG)"
        },
        {
          label: "+6 012 700 7738",
          value: "tel:+60127007738",
          note: "(MSIA)"
        }
      ]
    },
    {
      name: "Mr William Lee",
      designation: "Sales Executive",
      contacts: [
        {
          label: "william_lee@winspeclog.com.sg",
          value: "mailto:william_lee@winspeclog.com.sg"
        },
        {
          label: "+65 6865 1735",
          value: "tel:+6568651735"
        }
      ]
    },
  ]
})
menu.set("marine", {
  title: "Marine Oil Team",
  teams: [
    {
      name: "Mr Azim",
      designation: "Sales Manager",
      contacts: [
        {
          label: "azim@winspeclog.com.sg",
          value: "mailto:azim@winspeclog.com.sg"
        },
        {
          label: "+65 9182 0578",
          value: "tel:+6591820578"
        },
      ]
    },
    {
      name: "Ms Rina",
      designation: "Senior Account Administrator",
      contacts: [
        {
          label: "rina@winspeclog.com.sg",
          value: "mailto:rina@winspeclog.com.sg"
        },
        {
          label: "+65 6558 6257",
          value: "tel:+6565586257"
        }
      ]
    },
  ]
})

export default function Home(): React.JSX.Element {
  const [modalContact, setModalContact] = React.useState({
    opened: false,
    ...menu.get("logistic"),
  })

  useAutoPosition()
  useHashFragment()

  const openModalTeam = (t: string) => {
    setModalContact((prevState) => {
      return {
        ...prevState,
        ...menu.get(t),
        opened: true,
      }
    })
  }

  const services = [
    {
      menu: "Logistics",
      content: (
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:!col-span-6">
            <Image src={service1} alt="Winspec Warehouse"
              className="rounded-md object-fit" lazy />
          </div>
          <div className="col-span-12 md:!col-span-6">
            <div className="flex flex-col gap-4 py-4 md:!py-0 md:!px-4">
              <h2 className="text-2xl md:!text-3xl font-semibold text-center md:!text-left">
                Winspec Logistics Services
              </h2>
              <p className="leading-8">
                Established in 2006, Winspec Logistics currently specializes in warehousing and distribution. We have approximately 120,000 square feet of warehouse space at No. 6 and No. 10 Tuas Avenue 6. Our lorry distribution consists of 1-ton pick up, 1-ton lorry and 10-ton lorry to cater to any type of deliveries. We also provide logistics services for ship chandler.
              </p>
              <div className="flex justify-center items-center">
                <Button variant="primary" onClick={() => openModalTeam("logistic")}>
                  Meet the Team
                </Button>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      menu: "Shipping",
      content: (
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:!col-span-6">
            <Image src={service2} alt="Winspec Warehouse"
              className="rounded-md object-fit" lazy />
          </div>
          <div className="col-span-12 md:!col-span-6">
            <div className="flex flex-col gap-4 py-4 md:!py-0 md:!px-4">
              <h2 className="text-2xl md:!text-3xl font-semibold text-center md:!text-left">
                Winspec Shipping
              </h2>
              <p className="leading-8">
                Established in 2010 to provide international freight services. We concentrate on air and sea freight business regardless of Less Container Load or Full Container Load and cross-border between Singapore-Malaysia. Our good relationship with carriers in Singapore allows our rates to be one of the most competitive among other forwarders.
              </p>
              <div className="flex justify-center items-center">
                <Button variant="primary" onClick={() => openModalTeam("shipping")}>
                  Meet the Team
                </Button>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      menu: "Marine",
      content: (
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:!col-span-6">
            <Image src={service3} alt="Winspec Warehouse"
              className="rounded-md object-fit" lazy />
          </div>
          <div className="col-span-12 md:!col-span-6">
            <div className="flex flex-col gap-4 py-4 md:!py-0 md:!px-4">
              <h2 className="text-2xl md:!text-3xl font-semibold text-center md:!text-left">
                Winspec Marine Oil
              </h2>
              <p className="leading-8">
                Established since 2016 to carry out our own lubricant distribution business. We are the authorized distributor of ENOC (Emirates National Oil Company Group) Lubricants, a wholly owned company of the Government of Dubai. Winspec Marine provide the full range of Marine Lubricants to support all types of vessels requirements.
              </p>
              <div className="flex justify-center items-center">
                <Button variant="primary" onClick={() => openModalTeam("marine")}>
                  Meet the Team
                </Button>
              </div>
            </div>
          </div>
        </div>
      ),
    }
  ]

  return (
    <>
      <Meta disableTemplate>
        {{
          title: "Winspec Singapore",
          description: "Winspec Group is one of the Singapore&rsquo;s leading providers of freight forwarding and logistics who can provide one-stop logistics provider solution to our customers.",
          keywords: "winspec singapore, winspec group, winspec logistics services pte ltd, winspec shipping pte ltd, sea freight services, air freight services, warehousing, transportation, distribution, container trucking",
        }}
      </Meta>

      <Hero.Winspec
        primaryAction="/#about"
        backgroundImage={winspecHero} />

      <div id="about" className="scroll-my-16 px-3 sm:!px-14 md:!px-20 lg:!px-24 3xl:!px-64 pt-8 lg:!pt-12">
        <div className="grid grid-cols-12 gap-3 py-2">
          <div className="col-span-12">
            <div className="flex flex-col justify-center items-center lg:!items-start">
              <Image src={winspecLogo} alt="Winspec Logo" className="w-50 h-10" lazy />
              <h5 className="text-xl font-light">
                Sister Company of Allied
              </h5>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-3 py-2">
          <div className="col-span-12 lg:!col-span-6">
            <div className="flex flex-col gap-2 leading-8">
              <p>
                Winspec Group is one of Singapore’s leading freight-forwarding & logistics provider that offers one-stop logistics solutions to our customers. We provide services such as warehousing, local distribution, international freight and logistics solutions to our customers to support their Singapore businesses.
              </p>
              <p>
                Under the Winspec Group, we have the following entities:
              </p>
              <ol className="list-decimal list-inside">
                <li>Winspec Logistics Services Pte. Ltd.</li>
                <li>Winspec Shipping Pte. Ltd.</li>
                <li>Winspec Marine Oil Trading Pte. Ltd.</li>
              </ol>
            </div>
          </div>
          <div className="col-span-12 lg:!col-span-6 order-first lg:!order-last">
            <div className="flex justify-center items-center">
              <Image src={winspecAbout} alt="about" className="rounded-md w-full h-full" lazy />
            </div>
          </div>
        </div>
      </div>

      <div id="services" className="scroll-my-16 px-3 sm:!px-14 md:!px-20 lg:!px-24 3xl:!px-64 py-8 lg:!py-12">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12">
            <Carousel.Winspec enableTimer={!modalContact.opened} timerDuration={10000}>
              {{
                items: services
              }}
            </Carousel.Winspec>
          </div>
        </div>
      </div>

      <Modal
        portalId="overlay-root"
        size="md"
        opened={modalContact.opened}
        onClose={() => {
          setModalContact((prevState) => {
            return {
              ...prevState,
              opened: false
            }
          })
        }}
        closeable>
        {{
          header: (
            <h1 className="text-lg font-semibold">
              {modalContact.title}
            </h1>
          ),
          content: (
            <div className="border-t py-4 px-2">
              <div className="grid grid-cols-12 gap-6">
                {
                  modalContact.teams.map((team, i: number) => {
                    return (
                      <div key={`team-item-${i}`} className="col-span-12 md:!col-span-6">
                        <div className="flex flex-col justify-center items-start gap-1">
                          <h3 className="text-xl md:text-3xl font-semibold">
                            {team.name}
                          </h3>
                          <p className="font-light">{team.designation}</p>
                          {
                            team.contacts.length > 0 &&
                            <ul>
                              {
                                team.contacts.map((contact, j: number) => {
                                  return (
                                    <li key={`contact-item-${j}`}>
                                      <Link to={contact.value}
                                        className="text-blue-500">
                                        {contact.label}
                                      </Link>
                                      {
                                        contact.note &&
                                        <>
                                          {" "} <span>{contact.note}</span>
                                        </>
                                      }
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )
        }}
      </Modal>
    </>
  )
}
