import * as React from "react"
import { Outlet } from "react-router-dom"
import { Header } from "@allied/react-web/Header"
import { Footer } from "@allied/react-web/Footer"
import winspecLogo from "./../assets/logo/winspec-logo.png"
import facebookIcon from "./../assets/icon/facebook.svg"
import linkedinIcon from "./../assets/icon/linkedin.svg"

export default function Guest(): React.JSX.Element {
  return (
    <>
      <Header.Winspec
        logoImage={winspecLogo}
        logoUrl="/"
        linkItems={[
          {
            content: <>Home</>,
            to: "/"
          },
          {
            content: <>About</>,
            to: "/#about"
          },
          {
            content: <>Services</>,
            to: "/#services"
          },
          {
            content: <>Careers</>,
            to: "/careers"
          },
          {
            content: <>Contact</>,
            to: "/contact"
          },
        ]}
        menuItems={[
          {
            content: <>Login</>,
            to: "/login"
          },
          {
            content: <>Email Login</>,
            to: "https://marketplace.singtel.com/Account/Login",
            target: "_blank"
          },
        ]}
        sticky />
      <Outlet />
      <Footer.Winspec
        logoUrl="/"
        description="Sister Company of Allied"
        mediaItems={[
          {
            to: "https://www.facebook.com/AlliedContainerGroup/",
            content: (
              <img className="inline-block w-8 h-8"
                src={facebookIcon}
                alt="facebook" />
            ),
            target: "_blank"
          },
          {
            to: "https://www.linkedin.com/company/allied-container-group/",
            content: (
              <img className="inline-block w-8 h-8"
                src={linkedinIcon}
                alt="linkedin" />
            ),
            target: "_blank"
          }
        ]}
        menuItems={[
          {
            heading: "Company",
            items: [
              {
                content: "About",
                to: "/#about",
              },
              {
                content: "Services",
                to: "/#services",
              },
              {
                content: "Careers",
                to: "/careers",
              },
              {
                content: "Contact",
                to: "/contact",
              },
            ]
          },
          {
            heading: "Social",
            items: [
              {
                content: "Facebook",
                to: "https://www.facebook.com/WinspecGroupp/",
                target: "_blank"
              },
              {
                content: "Linkedin",
                to: "https://www.linkedin.com/company/winspecgroup/",
                target: "_blank"
              },
            ]
          },
          {
            heading: "Related",
            items: [
              {
                content: "Winspec Group",
                to: "https://www.winspecgroup.com/",
                target: "_blank",
              },
              {
                content: "Allied Food",
                to: "https://alliedfood.sg/",
                target: "_blank",
              }
            ]
          },
        ]} />
    </>
  )
}
